export const biomes = {
  forest: {
    color: "#00FF00",
  },
  mountains: {
    color: "#dddddd",
  },
  desert: {
    color: "#FFA500",
  },
  grass: {
    color: "#7CFF00",
  },
  water: {
    color: "#0000FF",
  },
}
