export const animals = {
  fish: {
    displayName: "Lachs",
    color: "blue",
  },
  bear: {
    displayName: "Bär",
    color: "#3d251e",
  },
  deer: {
    displayName: "Hirsch",
    color: "#765341",
  },
  bird: {
    displayName: "Bussard",
    color: "red",
  },
  fox: {
    displayName: "Fuchs",
    color: "orange",
  },
}
